<template>
  <div class="w-full">
    <div class="flex flex-row justify-between mb-8 font-medium">
      <el-select
        v-model="filter"
        placeholder="Filter"
        size="mini"
        class="mr-12"
        clearable
        style="max-width: 150px;"
      >
        <el-option
          v-for="item in filterOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
          <span style="float: left">{{ item.label }}</span>
        </el-option>
      </el-select>
      <el-input
        v-model="search"
        placeholder="Search Recipients"
        size="mini"
        class="flex"
        style="max-width: 200px"
      />
    </div>
    <RecipientsTable
      :communication="communication"
      :filterByEmail="search"
      :filterByEvent="filter"
    />
  </div>
</template>

<script>
export default {
  components: {
    RecipientsTable: () => import("./RecipientsTable.vue")
  },
  props: {
    communication: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data() {
    return {
      search: "",
      filter: "",
      filterOptions: [
        { label: "Processed", value: "processed" },
        { label: "Delivered", value: "delivered" },
        { label: "Open", value: "open" },
        { label: "Click", value: "click" },
        { label: "Bounce", value: "bounce" },
        { label: "Dropped", value: "dropped" }
      ]
    };
  }
};
</script>

<style scoped></style>
